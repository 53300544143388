import { useRef, useEffect } from "react";
import DynamicIcon from "./dynamic-icon";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import React from "react";
L.Icon.Default.imagePath = "leaflet_images/";

const MyMarker = (props) => {
  const leafletRef = useRef();
  useEffect(() => {
    leafletRef.current.openPopup();
  }, []);
  return <Marker ref={leafletRef} {...props} />;
};
const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>O nas</h6>
            <p className="text-justify">
              Nasz serwis swiadczy profesjonalne usługi związane z naprawą
              laptopów, komputerów, telefonów komórkowych, tabletów, monitorów
              itp.<br></br>
              <span style={{ color: "white" }}>Adres:</span> <br></br>ul.
              Brzeska 26, 21-500 Biała Podlaska [mapa]
              <DynamicIcon prefix={"Md"} icon={"MdNearMe"} />
            </p>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Co robimy</h6>
            <ul className="footer-links">
              <li>
                <a href="#">naprawa telefonów</a>
              </li>
              <li>
                <a href="#">instalowanie oprogramowania</a>
              </li>
              <li>
                <a href="#">odzyskiwanie danych</a>
              </li>
              <li>
                <a href="#">rozblokowanie, reset haseł</a>
              </li>
              <li>
                <a href="#">skany dokumentów</a>
              </li>
              <li>
                <a href="#">skup i spzedaz</a>
              </li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Godziny pracy</h6>
            <ul className="footer-links">
              <li>
                <span>Pon - Pt</span>
              </li>
              <li>
                <span>9:00 - 17:00</span>
              </li>
              <li>
                <span>Sobota</span>
              </li>
              <li>
                <span>9:00 - 14:00</span>
              </li>
            </ul>
          </div>
        </div>{" "}
        <div className="map-container" style={{ height: "300px" }}>
          <MapContainer
            center={[52.0349736, 23.1239358]}
            zoom={18}
            style={{
              doubleClickZoom: false,
              closePopupOnClick: false,
              dragging: false,
              zoomSnap: false,
              zoomDelta: false,
              trackResize: false,
              touchZoom: false,
              scrollWheelZoom: false,
              height: "300px",
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&region=PL&language=pl"
              subdomains={["mt1", "mt2", "mt3"]}
            />
            <MyMarker position={[52.0349736, 23.1239358]}>
              <Popup isOpen={true}>
                <b>Telekom-Serwis</b> <br /> Naprawy telefonów, laptopów itp.
                <br />
                ul.Brzeska 26, 21-500 Biała Podlaska
              </Popup>
            </MyMarker>
          </MapContainer>
        </div>
        <hr></hr>
      </div>

      <div className="container">
        <div className="row"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Copyright &copy; {new Date().getFullYear()} All Rights Reserved by
              <a href="https://telekom-serwis.pl" style={{ color: "#ffe600" }}>
                {" "}
                telekom-serwis.pl
              </a>
              .
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a
                  className="facebook outlined"
                  href="https://www.facebook.com/profile.php?id=100082868995852"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a className="twitter outlined" href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a className="telegram outlined" href="#">
                  <i className="fa fa-telegram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
