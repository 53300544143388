import React from "react";
import * as Si from "react-icons/si";
import * as Io from "react-icons/io5";
import * as Md from "react-icons/md";
import * as Vsc from "react-icons/vsc";
const instances = {
  Si,
  Io,
  Md,
  Vsc,
};
export default function DynamicIcon({ prefix, icon }) {
  console.log(prefix, icon);
  if (!prefix) return null;
  const Icon = instances[prefix][icon];
  return icon && <Icon />;
}
