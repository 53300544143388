import FlipCard from "./components/flip-card";
import {
  NavLink,
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { HeaderTitle } from "./components/header-title";
import "./App.css";
import { useState, createContext } from "react";
import Footer from "./components/footer";

import data from "./data.json";

export const Context = createContext();

const links = [
  {
    route: "/",
    name: "Start",
  },
  {
    route: "/oferta",
    name: "Oferta",
    title: "Nasza oferta",
  },
  {
    route: "/serwis",
    name: "Serwis",
    title: "Usługi i serwis",
  },
  {
    route: "/kontakt",
    name: "Kontakt",
    title: "Skontaktuj się z nami",
  },
];

const getLink = ({ route, name }, switchCard) => {
  return (
    <li className="navlink" key={route}>
      <NavLink
        to={route}
        title={name}
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={() => switchCard(null)}
      >
        {name}
      </NavLink>
    </li>
  );
};

function App() {
  const [activeCard, switchCard] = useState(0);

  return (
    <div className="App wrapper">
      <div className="container">
        <div className="overlay">
          <div className="header">
            <div className="logo">TELEKOM SERWIS</div>
            <div className="info">
              <i className="fa fa-phone-square"></i>
              <a href="tel:+48576358532" className="phone outlined">
                576 358 532
              </a>
            </div>
            {/* <nav>{links.map((link) => getLink(link, switchCard))}</nav> */}
          </div>
          <div className="">
            <HeaderTitle links={links} />
          </div>
          <Context.Provider value={activeCard}>
            <div className="main">
              <div className={"cardbox " + (activeCard == 0 ? "" : "flipped")}>
                {Object.values(data).map((item) => {
                  return (
                    <FlipCard
                      key={item.title}
                      switchCard={switchCard}
                      id={item.id}
                      fontSize={item.fontSize}
                      prefix={item.prefix}
                      iconName={item.iconName}
                    >
                      {item.title}
                    </FlipCard>
                  );
                })}
              </div>
              <div
                className={`information ${
                  activeCard ? "visible" : "invisible"
                } `}
              >
                {Object.values(data)
                  .filter((item) => item.id === activeCard)
                  .map((item) => (
                    <FlipCard
                      key={item.title}
                      switchCard={switchCard}
                      id={item.id}
                      fontSize={item.fontSize}
                      prefix={item.prefix}
                      iconName={item.iconName}
                      isClose={true}
                    >
                      {item.title}
                    </FlipCard>
                  ))}
              </div>
            </div>
          </Context.Provider>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
