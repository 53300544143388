import { useLocation } from "react-router-dom";

export function HeaderTitle({ links }) {
  const location = useLocation();
  const path = location.pathname;
  //console.log(path);
  const activeRoute = links.filter((link) => {
    //console.log(link);
    return link.route === path;
  });
  console.log("activeRoute", activeRoute);
  return (
    <div className="header-title">
      <h2
        className={
          activeRoute.length && activeRoute[0].name !== "Start"
            ? "active"
            : "active basic-header"
        }
      >
        {activeRoute[0].name !== "Start"
          ? activeRoute[0].name
          : "serwis telefonów i komputerów 24/7"}
      </h2>
    </div>
  );
}
