import React from "react";
import DynamicIcon from "./dynamic-icon";
import { useContext } from "react";
import { Context } from "../App";
const FlipCard = ({
  isClose,
  id,
  fontSize,
  iconName,
  prefix,
  children,
  switchCard,
}) => {
  //console.log(iconName);
  const active = useContext(Context);
  return (
    <div
      className={`card card${id} ${
        active == id ? "active" : active ? "inactive" : ""
      } ${isClose ? "formatted" : ""}`}
      onClick={() => {
        switchCard(id);
      }}
    >
      <div className="front">
        {isClose ? (
          <div
            className="close-btn"
            onClick={(e) => {
              switchCard(0);
              e.stopPropagation();
            }}
          >
            <div className="cardcontent" style={{ fontSize: `${5}rem` }}>
              <DynamicIcon prefix={"Md"} icon={"MdOutlineClose"} />
            </div>
          </div>
        ) : null}
        <div className="cardcontent main" style={{ fontSize: `${10}rem` }}>
          <DynamicIcon prefix={prefix || ""} icon={iconName || ""} />
          <div className="card-title" style={{ fontSize: `3rem` }}>
            {children}
          </div>
        </div>
      </div>
      <div
        className={`card-side card${id} ${
          active == id ? "active" : active ? "inactive" : ""
        }`}
      ></div>
    </div>
  );
};

export default FlipCard;
